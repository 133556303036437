<template>
	<el-container class="chat-page">
		<el-aside width="300px" class="chat-list-box">
			<div class="chat-list-header">
				<div class="chat-list-search">
					<el-input prefix-icon="el-icon-search" :placeholder="$t('comm.search')" v-model="searchText"></el-input>
				</div>
			</div>
			<div class="chat-list-loadding"  v-if="loading" v-loading="true" :element-loading-text="$t('comm.Receivingmsg')" element-loading-spinner="el-icon-loading" element-loading-background="#eee">
				<div class="chat-loading-box"></div>
			</div>
			<el-scrollbar class="chat-list-items">
				<div class="top-title" v-show="chatStore.topitems.length>0">
					<svg class="icon" aria-hidden="true"><use xlink:href="#icon-zhiding"></use></svg>
					<span>{{$t('comm.topMsg')}}</span>
				</div>
				<div v-for="(chat,index) in chatStore.topitems" :key="chat.targetId">
					<chat-item v-if ="chat.topnum"  :chat="chat" :index="index"
						@click.native="onActiveItem(chat)" @delete="onDelItem(chat)" @canceltop="onCancelTop(chat)" @bjunread="bjunread(chat)" @bjread="bjread(chat)"
						:active="chat === chatStore.activeChat" @turbing="turbing(chat)"></chat-item>
				</div>
				<div class="top-title">
					<svg class="icon" aria-hidden="true"><use xlink:href="#icon-huihua"></use></svg>
					<span>{{$t('comm.allmsg')}}</span>
					<el-select v-model="activateitem">
						<el-option :label="$t('comm.All')" :value="0"></el-option>
						<el-option :label="$t('comm.read')" :value="1"></el-option>
						<el-option :label="$t('comm.Unread')+(unreadCount>0?'('+showunreadCount+')':'')" :value="2"></el-option>
					</el-select>
				</div>
				<div v-for="(chat,index) in chatStore.chats" :key="index">
					<chat-item v-if ="!chat.topnum&&chat.groupType !='2'"  v-show="showitem(chat)&&(!searchText.trim()||chat.showName.includes(searchText))" :chat="chat" :index="index"
						@click.native="onActiveItem(chat)" @delete="onDelItem(chat)" @top="onTop(chat)"  @bjunread="bjunread(chat)" @bjread="bjread(chat)"
						:active="chat === chatStore.activeChat" @turbing="turbing(chat)"></chat-item>
				</div>
			</el-scrollbar>
			<div class="countnum" v-if="activateitem==0">
				{{ $t('comm.countChat',[countnum])}}
			</div>
		</el-aside>
		<el-container class="chat-box">
			<chat-box ref="chatbox" v-if="chatStore.activeChat" :chat="chatStore.activeChat" ></chat-box>
		</el-container>
	</el-container>
</template>

<script>
	import ChatItem from "../components/chat/ChatItem.vue";
	import FriendItem from "../components/friend/FriendItem.vue";
	import ChatBox from "../components/chat/ChatBox.vue";
	export default {
		name: "chat",
		components: {
			ChatItem,
			ChatBox,
			FriendItem,
		},
		data() {
			return {
				searchText: "",
				messageContent: "",
				group: {},
				groupMembers: [],
				friendid:"",
				activateitem:0
			}
		},
		mounted() {
		},
		methods: {
			bjread(chat){
				let index = this.findchatindex(chat)
				this.$store.commit("bjread", index);
			},
			bjunread(chat){
				let index = this.findchatindex(chat)
				this.$store.commit("bjunread", index);
			},
			findchatindex(chat){
				return this.chatStore.chats.findIndex(cs => cs == chat)
			},
			onActiveItem(chat) {
				let index = this.findchatindex(chat)
				this.$store.commit("activeChat", index);
				//activeGroup选中状态 控制当前群聊成员 禁言等功能
				if(chat.type=="GROUP"&&chat.groupType!="2"){
					let indx = this.groupStore.groups.findIndex(gs=>gs.id==chat.targetId)
					if(indx>=0){
						this.$store.commit("activeGroup", indx);
					}
				}
			},
			onActiveFriendItem(friend){
				const indx = this.chatStore.chats.findIndex((chat)=>{
					return chat.targetId === friend.id;
				})
				if(indx>=0){
					this.onActiveItem(indx)
				}else{
					let chat = {
						type: 'PRIVATE',
						targetId: friend.id,
						showName: friend.friendRemark || friend.nickName,
						headImage: friend.headImage,
						disturbing: friend.disturbing,
					}
					this.$store.commit("openChat", chat);
					this.$store.commit("activeChat", 0);
				}
			},
			//移除聊天
			onDelItem(chat) {
				const indx = this.chatStore.chats.findIndex((ct)=>{
					return ct.targetId === chat.targetId;
				})
				this.$store.commit("removeChat", indx);
			},
			onTop(chat) {
				let chatIdx = this.findchatindex(chat)
				this.$store.commit("top", chatIdx);
			},
			onCancelTop(chat){
				let chatIdx = this.findchatindex(chat)
				this.$store.commit("cleantop", chatIdx);
			},
			showitem(chat){
				if(this.activateitem == 0){
					return true
				}
				if(chat){
					if((this.activateitem == 1&&chat.unreadCount==0&&chat.messages.length>1||this.activateitem == 2&&chat.unreadCount>0)){
						return true
					}
				}
				return false
			},
			//消息免打扰开关
			turbing(chat){
				let setturbing = !chat.disturbing?1:0//免打扰开关修改的值
				if(chat.type =="GROUP"){
					let group = this.groupStore.groups.find(gd =>gd.id == chat.targetId)
					let vo = JSON.parse(JSON.stringify(group))
					vo.disturbing = setturbing
					this.$http({
					url: "/im/imGroup/modify",
						method: "put",
						data: vo
					}).then((group) => {
						this.$store.commit('updateChatFromGroup',{...group,disturbing:setturbing})
						this.$message.success(this.$t('comm.setsuccess'));
						if(chat==this.chatStore.activeChat){
							this.$refs.chatbox.reload()
						}
					})
				}else{
					let friend = this.friendStore.friends.find(fd =>fd.id == chat.targetId)
					this.$http({
						url: "/im/imFriend/updateFriendById",
						method:'PUT',
						data: {
							friendId:friend.id,
							disturbing:setturbing
						}
					}).then((res) => {
						this.$store.commit('updateChatFromFriend',{...friend,disturbing:setturbing})
						this.$message.success(this.$t('comm.setsuccess'));
						if(chat==this.chatStore.activeChat){
							this.$refs.chatbox.reload()
						}
					})
				}
			}
		},
		computed: {
			countnum(){
				return this.chatStore.chats.filter(ch=> ch.groupType !="2" ).length
			},
			friendStore() {
				return this.$store.state.friendStore;
			},
			chatStore() {
				return this.$store.state.chatStore;
			},
			groupStore() {
				return this.$store.state.groupStore;
			},
			loading(){
				return this.chatStore.loadingGroupMsg || this.chatStore.loadingPrivateMsg
			},
			unreadCount() {
				let unreadCount = 0;
				let chats = this.$store.state.chatStore.chats;
				chats.forEach((chat) => {
					unreadCount += chat.unreadCount

				});
				return unreadCount;
			},
			showunreadCount(){
				return this.unreadCount>99?"99+":this.unreadCount
			}
		}
	}
</script>

<style scoped lang="scss">
	.chat-page {
		.chat-list-box {
			display: flex;
			flex-direction: column;
			background: white;
			width: 3rem;
			padding-right: 40px;
			padding-left: 16px;
			.chat-list-header {
				padding: 20px 0px 16px 0px;
				display: flex;
				.chat-list-search {
					flex: 1;
					::v-deep{
						.el-input__icon{
							line-height: 40px;
						}
						input{
							font-size: 16px;
							color: #626569;
							background-color: #DBDCFF;
							border-radius: 12px;
							border: none;
							height: 40px;
							&::placeholder{
								color: #626569;
							}
						}
						.el-input__icon{
							color: #626569;
						}
					} 
				}
			}
			
			.chat-list-loadding{
				height: 50px;
				background-color: #eee;
				
				.chat-loading-box{
					height: 100%;
				}
			}
			.chat-list-items {
				flex: 1;
				margin-top: 8xp;
				.top-title{
					position: sticky;
					background: #fff;
					z-index: 1;
					top: 0;
					height: 40px;
					font-size: 14px;
					color: #6C757D;
					line-height: 16px;
					display: flex;
					align-items: center;
					.icon{
						margin: 8px;
						width: 16px;
						height: 16px;
					}
					.el-select{
						margin-left: auto;
						margin-right: 8px;
						width: 100px;
						::v-deep{
							input{
								border: none;
								height: 20px;
								padding: 0;
								font-weight: 500;
								font-size: 14px;
								color: #7678ED;
								text-align: right;
								padding-right: 25px;
								font-family: 'YourFont', sans-serif;
							}
							.el-input__suffix{
								right: 0;
							}
							.el-input__icon{
								line-height: 20px;
								font-weight: 600;
								color: #7678ED;
							}
						}
					}
				}
				::v-deep{
					.el-scrollbar__wrap{
						margin:0 !important;
					}
					.is-vertical{
						display: none;
					}
				}
			}

			.chat-list-content{
				height: 40px;
				box-sizing:border-box;
				margin-bottom:1px;
				ul{
					display:flex;
					flex-direction:row;
					justify-content: space-around;
					align-items: center;
					padding: 0;
					margin: 0px;
					li{
						flex:1;
						font-weight: 400;
						font-size: 14px;
						color: #595959;
    					font-size: 16px;
						list-style:none;
						cursor: pointer;
						position: relative;
						span{
							
							display: inline-block;
							width: auto;
							line-height: 39px;
						}
					}
					li.line{
						flex: none;
						width: 1px;
						height: 24px;
						background-color: #EEEEEE;
					}
					.activateli span{
						color: #1485EE;
						border-bottom:1px solid #1485EE;
					}
					.unread-text {
						position: absolute;
						line-height: 20px;
						background-color: #f56c6c;
					    left: 46px;
    					top: -2px;
						color: white;
						border-radius: 30px;
						padding: 0 5px;
						font-size: 10px;
						text-align: center;
						white-space: nowrap;
						border: 1px solid #f1e5e5;
					}
				}
				
			}
			.countnum{
				padding-left: 16px;
				height: 64px;
				line-height: 64px;
				font-size: 12px;
				color: #999999;
				text-align: left;
			}
		}
	}
</style>